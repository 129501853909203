import React from "react";
import HowItWorkBanner from "../../assets/images/backgrounds/howitworks-banner.jpg";
import CTAThreeBgShape from "../../assets/images/shapes/cta-three-bg-shape.png";
import WhyChooseFiveShape3 from "../../assets/images/shapes/why-choose-five-shape-3.png";
import Tracking from "../../assets/images/resources/tracking.jpg";
import SiteFooterFourShape1 from "../../assets/images/shapes/site-footer-four-shape-1.png";
import Logo2 from "../../assets/images/resources/logo-2.png";
import PricingOneShape4 from "../../assets/images/shapes/pricing-one-shape-4.png";
import WhyChooseFiveShape1 from "../../assets/images/shapes/why-choose-five-shape-1.png";
import WhyChooseFiveShape2 from "../../assets/images/shapes/why-choose-five-shape-2.png";
import Trucker from "../../assets/images/trucker.png";
import "./hiw.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import TalentSyncLogo from "../../assets/images/talentSync_Logo.png";

const HowItWorks = () => {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const DrawerList = (
    <Box
      sx={{ width: 250, backgroundColor: "#040b1e", color: "white" }}
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      <div
        style={{
          display: "flex ",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          height: "fit-content",
        }}
      >
        <img src={TalentSyncLogo} style={{ width: "55%" }} alt="logo"></img>
        <span
          className="mobile-nav__close mobile-nav__toggler"
          style={{ marginTop: "5%" }}
        >
          <i className="fa fa-times" onClick={toggleDrawer(false)}></i>
        </span>
      </div>
      <List>
        {/* Navigation Items */}
        <ListItem button component="a" href="/">
          <ListItemText
            primary="Home"
            sx={{ textAlign: "left", color: "white" }}
          />
        </ListItem>
        <Divider />
        <ListItem button component="a" href="/about">
          <ListItemText
            primary="About"
            sx={{ textAlign: "left", color: "white" }}
          />
        </ListItem>
        <Divider />
        <ListItem button component="a" href="/pricing">
          <ListItemText
            primary="Pricing"
            sx={{ textAlign: "left", color: "white" }}
          />
        </ListItem>
        <Divider />
        <ListItem button component="a" href="/how-it-works">
          <ListItemText
            primary="How It Works"
            sx={{ textAlign: "left", color: "white" }}
          />
        </ListItem>
        <Divider />
        <ListItem button component="a" href="/contact">
          <ListItemText
            primary="Contact"
            sx={{ textAlign: "left", color: "white" }}
          />
        </ListItem>
        <Divider />
      </List>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "20px",
          marginTop: "8%",
        }}
      >
        <a href="#" className="fab fa-twitter" style={{ color: "white" }}></a>
        <a
          href="#"
          className="fab fa-facebook-square"
          style={{ color: "white" }}
        ></a>
        <a
          href="#"
          className="fab fa-pinterest-p"
          style={{ color: "white" }}
        ></a>
        <a href="#" className="fab fa-instagram" style={{ color: "white" }}></a>
      </div>
    </Box>
  );
  return (
    <div>
      <div className="page-wrapper">
        <header className="main-header-four">
          <div className="main-header-four__top">
            <div className="container">
              <div className="main-header-four__top-inner">
                <div className="main-header-four__top-left">
                  <div className="main-header-four__logo">
                    {/* <!-- <a href="/"><img src="assets/images/resources/talentsync-logo.png" alt=""></a> --> */}
                    <a href="/">
                      <img src={TalentSyncLogo} style={{ width: "20%" }}></img>
                    </a>
                  </div>
                  {/* <!-- <div className="main-header-four__search-box">
                                <form action="#" className="main-header-four__search-form">
                                    <input type="search" placeholder="Search....">
                                    <button type="submit"><i className="icon-search"></i></button>
                                </form>
                            </div> --> */}
                </div>
                <ul className="list-unstyled main-header-four__contact-list">
                  <li>
                    <div className="text">
                      <span>email</span>
                      <p>
                        <a href="mailto:support@mail.talentsync.agency">
                          support@mail.talentsync.agency
                        </a>
                      </p>
                    </div>
                  </li>
                  {/* <!-- <li>
                                <div className="text">
                                    <span>PHONE</span>
                                    <p><a href="tel:18000000000">1800 000 0000 </a></p>
                                </div>
                            </li> --> */}
                </ul>
              </div>
            </div>
          </div>
          <nav className="main-menu main-menu-four">
            <div className="main-menu-four__wrapper">
              <div className="container">
                <div className="main-menu-four__wrapper-inner">
                  <div className="main-menu-four__left">
                    <div className="main-menu-four__main-menu-box">
                      <a className="mobile-nav__toggler">
                        <i
                          className="fa fa-bars"
                          onClick={toggleDrawer(true)}
                        ></i>
                      </a>
                      <Drawer
                        sx={{
                          "& .MuiDrawer-paper": {
                            backgroundColor: "#040b1e",
                            px: "15px",
                            py: "30px",
                          },
                        }}
                        open={open}
                        onClose={toggleDrawer(false)}
                      >
                        {DrawerList}
                      </Drawer>
                      <ul className="main-menu__list">
                        <li className="dropdown">
                          <a href="/">Home </a>
                        </li>
                        <li>
                          <a href="/about">About</a>
                        </li>

                        <li>
                          <a href="/pricing">Pricing</a>
                        </li>

                        <li>
                          <a href="/how-it-works">How It Works</a>
                        </li>
                        <li>
                          <a href="/contact">Contact</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="main-menu-four__right">

                    {/* <div className="main-menu-four__btn-box">
                      <a
                        href="/contact"
                        className="main-menu-four__btn thm-btn"
                      >
                        GET A QUOTE
                      </a>
                    </div> */}
                    <div
                      className="main-menu-four__btn-box"
                      style={{ marginLeft: "10px" }}
                    >
                      <a href="/login" className="main-menu-four__btn thm-btn">
                        Log In
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header>

        <div className="stricky-header stricked-menu main-menu main-menu-four">
          <div className="sticky-header__content"></div>
        </div>

        {/* <!--Page Header Start--> */}
        <section className="page-header">
          <div
            className="page-header__bg"
            style={{ backgroundImage: `url(${HowItWorkBanner})` }}
          ></div>
          <div className="container">
            <div className="page-header__inner">
              <h2>How It Works</h2>
              <div className="thm-breadcrumb__box">
                <ul className="thm-breadcrumb list-unstyled">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <span>-</span>
                  </li>
                  <li>How It Works</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        {/* <!--Page Header End--> */}

        <section className="pricing-one">
          <div className="pricing-one__shape-4">
            <img src={PricingOneShape4} alt="" />
          </div>
          <div className="container">
            <div className="section-title text-center">
              <h2 className="section-title__title">How It Works</h2>
              <br />
              <p>
                A wide range of Recruitment, Market Research, Business
                Development, and related Consultancy solutions with a common
                goal of helping your company succeed
              </p>
            </div>
          </div>
        </section>

        <section className="why-choose-three why-choose-five">
          <div className="why-choose-three__shape-1">
            <img src={WhyChooseFiveShape1} alt="" />
          </div>
          <div className="why-choose-three__shape-2 img-bounce">
            <img src={WhyChooseFiveShape2} alt="" />
          </div>
          <div className="why-choose-three__shape-3 float-bob-x">
            <img src={WhyChooseFiveShape3} alt="" />
          </div>
          <div className="container">
            <div className="section-title text-center">
              {/* <!-- <div className="section-title__tagline-box">
                        <p className="section-title__tagline">Who We Are</p>
                    </div> --> */}
              <h2 className="section-title__title">From Your Side</h2>
            </div>
            <div className="row">
              {/* <!--Why Choose Three Single Start--> */}
              <div
                className="col-xl-4 col-lg-4 wow fadeInLeft"
                data-wow-delay="100ms"
              >
                <div className="why-choose-three__single">
                  <div className="why-choose-three__icon">
                    <span className="icon-right-chevron"></span>
                  </div>
                  <div className="why-choose-three__content">
                    <h3>
                      <a href="/about">Create an Account</a>
                    </h3>
                    <p>Sign up and create your account to get started.</p>
                  </div>
                </div>
              </div>
              {/* <!--Why Choose Three Single End-->
                    <!--Why Choose Three Single Start--> */}
              <div
                className="col-xl-4 col-lg-4 wow fadeInRight"
                data-wow-delay="300ms"
              >
                <div className="why-choose-three__single">
                  <div className="why-choose-three__icon">
                    <span className=" icon-right-chevron"></span>
                  </div>
                  <div className="why-choose-three__content">
                    <h3>
                      <a href="/about">Purchase Our Service</a>
                    </h3>
                    <p>Select the service and make a purchase.</p>
                  </div>
                </div>
              </div>
              {/* <!--Why Choose Three Single End-->
                    <!--Why Choose Three Single Start--> */}
              <div
                className="col-xl-4 col-lg-4 wow fadeInLeft"
                data-wow-delay="600ms"
              >
                <div className="why-choose-three__single">
                  <div className="why-choose-three__icon">
                    <span className="icon-right-chevron"></span>
                  </div>
                  <div className="why-choose-three__content">
                    <h3>
                      <a href="/about">Complete Your Profile</a>
                    </h3>
                    <p>Fill out your profile with relevant information.</p>
                  </div>
                </div>
              </div>
              {/* <!--Why Choose Three Single End-->
                    <!--Why Choose Three Single Start--> */}
              {/* <!-- <div className="col-xl-6 col-lg-6 wow fadeInRight" data-wow-delay="900ms">
                        <div className="why-choose-three__single">
                            <div className="why-choose-three__icon">
                                <span className="icon-save-money-1"></span>
                            </div>
                            <div className="why-choose-three__content">
                                <h3><a href="/about">Deposite Money</a></h3>
                                <p>Through a unique coN construction and design dis
                                    nes expertise Concor and delivers</p>
                            </div>
                        </div>
                    </div> --> */}
              {/* <!--Why Choose Three Single End--> */}
            </div>
          </div>
        </section>

        <section className="why-choose-three why-choose-five">
          <div className="why-choose-three__shape-1">
            <img src={WhyChooseFiveShape1} alt="" />
          </div>
          <div className="why-choose-three__shape-2 img-bounce">
            <img src={WhyChooseFiveShape2} alt="" />
          </div>
          <div className="why-choose-three__shape-3 float-bob-x">
            <img src={WhyChooseFiveShape3} alt="" />
          </div>
          <div className="container">
            <div className="section-title text-center">
              {/* <!-- <div className="section-title__tagline-box">
                        <p className="section-title__tagline">Who We Are</p>
                    </div> --> */}
              <h2 className="section-title__title">From Our Side</h2>
            </div>
            <div className="row">
              {/* <!--Why Choose Three Single Start--> */}
              <div
                className="col-xl-6 col-lg-6 wow fadeInLeft"
                data-wow-delay="100ms"
              >
                <div className="why-choose-three__single">
                  <div className="why-choose-three__icon">
                    <span className="icon-checked"></span>
                  </div>
                  <div className="why-choose-three__content">
                    <h3>
                      <a href="/about">Profile Analysis</a>
                    </h3>
                    <p>
                      Our team will analyze your profile. If any information
                      needs to be edited, we will reach out to you to make the
                      necessary edits.
                    </p>
                  </div>
                </div>
              </div>
              {/* <!--Why Choose Three Single End-->
                    <!--Why Choose Three Single Start--> */}
              <div
                className="col-xl-6 col-lg-6 wow fadeInRight"
                data-wow-delay="300ms"
              >
                <div className="why-choose-three__single">
                  <div className="why-choose-three__icon">
                    <span className=" icon-checked"></span>
                  </div>
                  <div className="why-choose-three__content">
                    <h3>
                      <a href="/about">Market Analysis</a>
                    </h3>
                    <p>
                      We conduct a comprehensive market analysis to tailor our
                      services to your specific needs.
                    </p>
                  </div>
                </div>
              </div>
              {/* <!--Why Choose Three Single End-->
                    <!--Why Choose Three Single Start--> */}
              <div
                className="col-xl-6 col-lg-6 wow fadeInLeft"
                data-wow-delay="600ms"
              >
                <div className="why-choose-three__single">
                  <div className="why-choose-three__icon">
                    <span className="icon-checked"></span>
                  </div>
                  <div className="why-choose-three__content">
                    <h3>
                      <a href="/about">Email Crafting</a>
                    </h3>
                    <p>
                      Our experts craft personalized emails to maximize
                      engagement.
                    </p>
                  </div>
                </div>
              </div>
              {/* <!--Why Choose Three Single End-->
                    <!--Why Choose Three Single Start--> */}
              <div
                className="col-xl-6 col-lg-6 wow fadeInRight"
                data-wow-delay="900ms"
              >
                <div className="why-choose-three__single">
                  <div className="why-choose-three__icon">
                    <span className="icon-checked"></span>
                  </div>
                  <div className="why-choose-three__content">
                    <h3>
                      <a href="/about">Forwarding</a>
                    </h3>
                    <p>
                      We handle the forwarding process efficiently to ensure
                      seamless communication, each week around 250 emails for a
                      period of 4 weeks.
                    </p>
                  </div>
                </div>
              </div>
              {/* <!--Why Choose Three Single End--> */}
            </div>
          </div>
        </section>

        <section className="about-four">
          <div className="container">
            <div className="row">
              <div className="col-xl-6">
                <div className="about-three__left">
                  <div>
                    <img src={Trucker} style={{ maxWidth: "600px" }} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-xl-6">
                <div className="about-four__right">
                  <div className="section-title text-left">
                    <h5 className="section-title__title">Tracking Progress</h5>
                  </div>
                  <p className="about-four__text">
                   You can monitor progress using our unique tracking system, the 'Trucker.' This will allow you to see interested individuals based on their open rates
                  </p>

                  <div className="section-title text-left">
                    {/* <!-- <div className="section-title__tagline-box">
                                    <p className="section-title__tagline">Finds You the Perfect Job</p>
                                </div> --> */}
                    <h5 className="section-title__title">
                      Trucker <span>Information</span>
                    </h5>
                  </div>
                  <p className="about-four__text">
                    Receive hourly updates on the progress. The Trucker includes
                    details on:
                  </p>

                  <div className="listflex">
                    <ul className="about-four__points list-unstyled">
                      <li>
                        <div className="icon">
                          <span className="icon-check-mark"></span>
                        </div>
                        <p>Contacted Person</p>
                      </li>
                      <li>
                        <div className="icon">
                          <span className="icon-check-mark"></span>
                        </div>
                        <p>HR LinkedIn Account</p>
                      </li>
                      <li>
                        <div className="icon">
                          <span className="icon-check-mark"></span>
                        </div>
                        <p>Contacted Emails</p>
                      </li>
                    </ul>
                    <ul className="about-four__points list-unstyled">
                      <li>
                        <div className="icon">
                          <span className="icon-check-mark"></span>
                        </div>
                        <p>Company Information</p>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* <!--Insurence Page Start--> */}

        {/* <!--CTA One Start --> */}
        <section className="cta-one cta-three">
          <div className="container">
            <div className="cta-one__inner">
              <div className="cta-one__bg" style={{ backgroundImage: `url('/static/media/cta-three-bg-shape.e9c0161ef88e1fcbc1f1.png')` }}></div>
              <div className="cta-one__title-box">
                <h3>Are you Intrested in <span>Free</span> Service.</h3>
                <p>We are here to help you. Currently we are in a testing phase if you are intrested to work together drop us a mail <br /> Lets Grow Together.</p>
              </div>
              <div className="cta-one__btn-box">
                <a href="/contact" className="cta-one__btn thm-btn">Drop US A Mail</a>
              </div>
            </div>
          </div>
        </section>
        {/* <!--CTA One End --> */}

        {/* <!--Site Footer Start--> */}
        
        
        <footer className="site-footer site-footer-three site-footer-four">
          <div className="site-footer__shape-1 float-bob-y">
            <img src={SiteFooterFourShape1} alt="" />
          </div>
          <div className="site-footer__top">
            <div className="container">
              <div className="site-footer__top-inner">
                <div className="row">
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 wow fadeInUp"
                    data-wow-delay="100ms"
                  >
                    <div className="footer-widget__column footer-widget__about">
                      <div className="footer-widget__logo">
                        {/* <!-- <a href="/"><img src="assets/images/resources/footer-logo-4.png"
                                                alt=""></a> --> */}
                        <a href="/">
                          <h2 style={{ color: "white" }}>Talentsync</h2>
                        </a>
                      </div>
                      <p className="footer-widget__about-text">
                        Get Noticed by Employers: TalentSync Finds You the
                        Perfect Job
                      </p>
                      <div className="footer-widget__emergency-call">
                        <a href="mailto:support@mail.talentsync.com">
                          support@mail.talentsync.com
                        </a>
                      </div>
                      {/*
                      <div className="footer-widget__social">
                        <a href="#">
                          <span className="icon-facebook"></span>
                        </a>
                        <a href="#">
                          <span className="icon-instagram-1"></span>
                        </a>
                        <a href="#">
                          <span className="icon-tik-tok"></span>
                        </a>
                        <a href="#">
                          <span className="icon-youtube"></span>
                        </a>
                      </div>
                      */}
                    </div>
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 wow fadeInUp"
                    data-wow-delay="200ms"
                  >
                    <div className="footer-widget__column footer-widget__navigation">
                      <div className="footer-widget__title-box">
                        <h3 className="footer-widget__title">Navigation</h3>
                      </div>
                      <ul className="footer-widget__navigation-list list-unstyled">
                        <li>
                          <a href="/">Home</a>
                        </li>
                        <li>
                          <a href="/about">About Us</a>
                        </li>
                        <li>
                          <a href="/how-it-works">How It Works</a>
                        </li>
                        <li>
                          <a href="/pricing">Pricing</a>
                        </li>
                        <li>
                          <a href="/contact">Contact Us</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="site-footer__bottom">
            <div className="container">
              <div className="site-footer__bottom-inner">
                <p className="site-footer__bottom-text">
                  <a href="/PrivacyPolicy">Privacy Policy</a> &nbsp;|&nbsp; Copyright © 2024 Talentsync.Agency. All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </footer>


      </div>

      <div className="mobile-nav__wrapper">
        <div className="mobile-nav__overlay mobile-nav__toggler"></div>
        <div className="mobile-nav__content">
          <span className="mobile-nav__close mobile-nav__toggler">
            <i className="fa fa-times"></i>
          </span>

          <div className="logo-box">
            <a href="/" aria-label="logo image">
              <img src={Logo2} width="150" alt="" />
            </a>
          </div>

          <div className="mobile-nav__container"></div>

          <ul className="mobile-nav__contact list-unstyled">
            <li>
              <i className="fa fa-envelope"></i>
              <a href="mailto:needhelp@packageName__.com">
                needhelp@sonchoy.com
              </a>
            </li>
            <li>
              <i className="fa fa-phone-alt"></i>
              <a href="tel:666-888-0000">666 888 0000</a>
            </li>
          </ul>
          {/*{/*
    <div className="mobile-nav__top">
      <div className="mobile-nav__social">
        <a href="#" className="fab fa-twitter"></a>
        <a href="#" className="fab fa-facebook-square"></a>
        <a href="#" className="fab fa-pinterest-p"></a>
        <a href="#" className="fab fa-instagram"></a>
      </div>
    </div>
  */}*/}
        </div>
        {/* <!-- /.mobile-nav__content --> */}
      </div>
      {/* <!-- /.mobile-nav__wrapper --> */}

      <div className="search-popup">
        <div className="search-popup__overlay search-toggler"></div>
        {/* <!-- /.search-popup__overlay --> */}
        <div className="search-popup__content">
          <form action="#">
            <label for="search" className="sr-only">
              search here
            </label>
            <input type="text" id="search" placeholder="Search Here..." />
            <button
              type="submit"
              aria-label="search submit"
              className="thm-btn"
            >
              <i className="fas fa-search"></i>
            </button>
          </form>
        </div>
        {/* <!-- /.search-popup__content --> */}
      </div>
      {/* <!-- /.search-popup --> */}

      <a href="#" data-target="html" className="scroll-to-target scroll-to-top">
        <i className="fas fa-arrow-up"></i>
      </a>
    </div>
  );
};

export default HowItWorks;
