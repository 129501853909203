import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Badge,
} from "reactstrap";
import Select from "react-select";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SnackbarComponent from "../../../components/Snackbar/Snackbar";
import SiteFooterFourShape1 from "../../../assets/images/shapes/site-footer-four-shape-1.png";
import Logo2 from "../../../assets/images/resources/logo-2.png";
import AboutDetail3 from "../../../assets/images/resources/about-detail3.jpg";
import CTAThreeBgShape from "../../../assets/images/shapes/cta-three-bg-shape.png";
import { backendUrl } from "../../../constants/config";
import { Backdrop, Box, CircularProgress, Grid, Menu } from "@mui/material";
import MenuDrawer from "../../../components/MenuDrawer/MenuDrawer";
import AppBar from "../../../components/AppBar/AppBar";
import TitleWithSearchBar from "../../../components/TitleWithSearchBar/TitleWithSearchBar";
import TitleWithoutSearchBar from "../../../components/TitleWithoutSearchBar/TitleWithoutSearchBar";

const Profile = () => {
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [inputValue, setInputValue] = useState("");

  const handleAddCompany = () => {
    if (inputValue.trim() !== "") {
      const newCompany = { value: inputValue, label: inputValue };
      setSelectedCompanies([...selectedCompanies, newCompany]);
      setInputValue(""); // Clear the input field
    }
  };

  const handleMultiChange = (selectedOptions) => {
    setSelectedCompanies(selectedOptions || []);
  };
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [countries, setCountries] = useState([]);
  const [error, seterror] = useState(false);
  const [errorMsg, seterrorMsg] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(null);
  const [success, setSuccess] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    age: "",
    email: "",
    referralSource: "",
    nationality: "",
    residency: "",
    jobTitles: "",
    experience: "",
    contact: "",
    avatar: null,
    avoidCompanies: [],
    education: "",
    educationCert: null, // Education certificate file
    professionalCert: null, // Professional certificate file
    industries: [],
    targetCompanySize: "",
    country: "",
    state: "",
    city: "",
    resume: null,
    coverLetter: "",
    additionalInfo: "",
  });

  const handleSelectChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const industries = [
    "Accounting",
    "Agriculture",
    "Airlines/Aviation",
    "Alternative Dispute Resolution",
    "Alternative Medicine",
    "Animation",
    "Apparel & Fashion",
    "Architecture & Planning",
    "Arts & Crafts",
    "Automotive",
    "Aviation & Aerospace",
    "Banking",
    "Biotechnology",
    "Broadcast Media",
    "Building Materials",
    "Business Supplies & Equipment",
    "Capital Markets",
    "Chemicals",
    "Civic & Social Organization",
    "Civil Engineering",
    "Commercial Real Estate",
    "Computer & Network Security",
    "Computer Games",
    "Computer Hardware",
    "Computer Networking",
    "Computer Software",
    "Construction",
    "Consumer Electronics",
    "Consumer Goods",
    "Consumer Services",
    "Cosmetics",
    "Dairy",
    "Defense & Space",
    "Design",
    "E-Learning",
    "Education Management",
    "Electrical/Electronic Manufacturing",
    "Entertainment",
    "Environmental Services",
    "Events Services",
    "Executive Office",
    "Facilities Services",
    "Farming",
    "Financial Services",
    "Fine Art",
    "Fishery",
    "Food & Beverages",
    "Food Production",
    "Fund-Raising",
    "Furniture",
    "Gambling & Casinos",
    "Glass, Ceramics & Concrete",
    "Government Administration",
    "Government Relations",
    "Graphic Design",
    "Health, Wellness & Fitness",
    "Higher Education",
    "Hospital & Health Care",
    "Hospitality",
    "Human Resources",
    "Import & Export",
    "Individual & Family Services",
    "Industrial Automation",
    "Information Services",
    "Information Technology & Services",
    "Insurance",
    "International Affairs",
    "International Trade & Development",
    "Internet",
    "Investment Banking",
    "Investment Management",
    "Judiciary",
    "Law Enforcement",
    "Law Practice",
    "Legal Services",
    "Legislative Office",
    "Leisure, Travel & Tourism",
    "Libraries",
    "Logistics & Supply Chain",
    "Luxury Goods & Jewelry",
    "Machinery",
    "Management Consulting",
    "Maritime",
    "Market Research",
    "Marketing & Advertising",
    "Mechanical or Industrial Engineering",
    "Media Production",
    "Medical Devices",
    "Medical Practice",
    "Mental Health Care",
    "Military",
    "Mining & Metals",
    "Motion Pictures & Film",
    "Museums & Institutions",
    "Music",
    "Nanotechnology",
    "Newspapers",
    "Nonprofit Organization Management",
    "Oil & Energy",
    "Online Media",
    "Outsourcing/Offshoring",
    "Package/Freight Delivery",
    "Packaging & Containers",
    "Paper & Forest Products",
    "Performing Arts",
    "Pharmaceuticals",
    "Philanthropy",
    "Photography",
    "Plastics",
    "Political Organization",
    "Primary/Secondary Education",
    "Printing",
    "Professional Training & Coaching",
    "Program Development",
    "Public Policy",
    "Public Relations & Communications",
    "Public Safety",
    "Publishing",
    "Railroad Manufacture",
    "Ranching",
    "Real Estate",
    "Recreational Facilities & Services",
    "Religious Institutions",
    "Renewables & Environment",
    "Research",
    "Restaurants",
    "Retail",
    "Security & Investigations",
    "Semiconductors",
    "Shipbuilding",
    "Sporting Goods",
    "Sports",
    "Staffing & Recruiting",
    "Supermarkets",
    "Telecommunications",
    "Textiles",
    "Think Tanks",
    "Tobacco",
    "Translation & Localization",
    "Transportation/Trucking/Railroad",
    "Utilities",
    "Venture Capital & Private Equity",
    "Veterinary",
    "Warehousing",
    "Wholesale",
    "Wine & Spirits",
    "Wireless",
    "Writing & Editing",
  ];

  const industryOptions = [
    { value: "", label: "Select Industry" },
    ...industries.map((industry) => ({ value: industry, label: industry })),
  ];
  const educationLevelOptions = [
    { value: "", label: "Select Education Level" },
    { value: "Secondary School", label: "Secondary School" },
    {
      value: "Certificate/Diploma Programs",
      label: "Certificate/Diploma Programs",
    },
    { value: "Associate's Degree", label: "Associate's Degree" },
    { value: "Bachelor's Degree", label: "Bachelor's Degree" },
    { value: "Master's Degree", label: "Master's Degree" },
    { value: "Doctorate/Ph.D.", label: "Doctorate/Ph.D." },
  ];

  const companySizeOptions = [
    { value: "", label: "Select Desired Company Size" },
    {
      value: "Any Company Size",
      label: "Any Company Size",
    },
    {
      value: "Small (less than 50 employees)",
      label: "Small (less than 50 employees)",
    },
    { value: "Mid (50-500 employees)", label: "Mid (50-500 employees)" },
    {
      value: "Large (500-5000 employees)",
      label: "Large (500-5000 employees)",
    },
    {
      value: "International (5000+ employees)",
      label: "International (5000+ employees)",
    },
  ];

  useEffect(() => {
    const talentSyncUser = JSON.parse(localStorage.getItem("TalentSyncUser"));
    if (talentSyncUser && talentSyncUser.name) {
      setFormData((prevState) => ({
        ...prevState,
        name: talentSyncUser.name,
        age: talentSyncUser.age,
        email: talentSyncUser.email,
        referralSource: talentSyncUser.source,
        nationality: talentSyncUser.nationality,
        residency: talentSyncUser.residency,
        jobTitles: talentSyncUser.job_title,
        experience: talentSyncUser.experience,
        education: talentSyncUser.education_level,
        educationCert: talentSyncUser.education_certificate, // Education certificate file
        professionalCert: talentSyncUser.professional_certificate, // Professional certificate file
        industries: talentSyncUser.industries,
        targetCompanySize: talentSyncUser.target_company_size,
        country: talentSyncUser.country,
        state: talentSyncUser.state,
        city: talentSyncUser.city,
        resume: talentSyncUser.resume,
        coverLetter: talentSyncUser.cover_letter,
        additionalInfo: talentSyncUser.additional_info,
        contact: talentSyncUser.contact,
        avatar: talentSyncUser.avatar,
      }));

      if (talentSyncUser.avoidCompanies?.length > 0) {
        setFormData((prevState) => ({
          ...prevState,
          avoidCompanies: talentSyncUser.avoidCompanies,
        }));
      }

      setUser(talentSyncUser);
    }
    setStates([{ value: "", label: "Select State" }]);
    setCities([{ value: "", label: "Select City" }]);
  }, []);

  useEffect(() => {
    fetchCountries();
  }, []);

  const addAvoidCompany = () => {
    if (avoidCompany !== "") {
      setFormData((prevState) => ({
        ...prevState,
        avoidCompanies: [...(prevState.avoidCompanies || []), avoidCompany], // Use prevState for up-to-date state
      }));

      setAvoidCompany("");
    }
  };

  const [selectedIndustry, setSelectedIndustry] = useState("");

  const handleIndustryChange = (e) => {
    const value = e.target.value;

    // Ensure formData.industries is an array
    const industries = formData.industries || [];

    // Add to industries array if it's not already there
    if (value && !industries.includes(value)) {
        setFormData({
            ...formData,
            industries: [...industries, value],
        });
    }
    setSelectedIndustry(""); // Reset dropdown
  };

  const removeIndustry = (industry) => {
    // Ensure formData.industries is an array
    const industries = formData.industries || [];

    setFormData({
        ...formData,
        industries: industries.filter((item) => item !== industry),
    });
  };

  const removeAvoidCompany = (name) => {
    setFormData((prevState) => ({
      ...prevState,
      avoidCompanies: [...formData.avoidCompanies?.filter((i) => i !== name)],
    }));
    console.log(
      name,
      formData.avoidCompanies?.filter((i) => i !== name)
    );
  };

  useEffect(() => {
    if (formData.country) {
      const selectedCountry = countries.find(
        (country) => country.value === formData.country
      );
      if (selectedCountry) fetchStates(selectedCountry.code);
    }
  }, [formData.country]);

  useEffect(() => {
    if (formData.state) {
      const selectedCountry = countries.find(
        (country) => country.value === formData.country
      );
      const selectedState = states.find(
        (state) => state.value === formData.state
      );
      if (selectedCountry && selectedState)
        fetchCities(selectedCountry.code, selectedState.code);
    }
  }, [formData.state]);

  const handleFileUpload = async (file, type) => {
    if (!file) return;
    setLoading(true);
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        `${backendUrl}/aws/file?email=TalentSync`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      // Update the corresponding field in formData
      setFormData((prevState) => ({
        ...prevState,
        [type]: response.data.url,
      }));

      setSuccess(`${type} uploaded successfully!`);
    } catch (err) {
      console.error("Error uploading file:", err);
      setErr("Error uploading file. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      handleFileUpload(files[0], name);
    }
  };

  const fetchCountries = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://api.countrystatecity.in/v1/countries",
        {
          headers: {
            "X-CSCAPI-KEY":
              "VElGVWJxblkwQWlaTlVtUVRTZTlKSHRvSnJhZDBKenVBS2l0am5hdw==",
          },
        }
      );
      const filteredCountries = response.data.map((country) => ({
        label: country.name,
        value: country.name,
        code: country.iso2,
      }));

      setCountries([
        { label: "Select Country", value: "" },
        ...filteredCountries,
      ]);
      setLoading(false);
    } catch (error) {
      setErr("Error fetching countries.");
      setLoading(false);
    }
  };

  useEffect(() => {
    if (formData.country) {
      const selectedCountry = countries.find(
        (country) => country.value === formData.country
      );

      if (selectedCountry) {
        fetchStates(selectedCountry.code).catch((error) => {
          console.error("Error fetching states:", error);
          setErr("Unable to fetch states.");
        });
      }
    }
  }, [formData.country, countries]);

  useEffect(() => {
    if (formData.state) {
      const selectedCountry = countries.find(
        (country) => country.value === formData.country
      );
      const selectedState = states.find(
        (state) => state.value === formData.state
      );

      if (selectedCountry && selectedState) {
        fetchCities(selectedCountry.code, selectedState.code).catch((error) => {
          console.error("Error fetching cities:", error);
          setErr("Unable to fetch cities.");
        });
      }
    }
  }, [formData.state, states, countries]);

  const [avoidCompany, setAvoidCompany] = useState("");

  const fetchStates = async (countryCode) => {
    if (!countryCode) return; // Prevent unnecessary calls if no country code
    try {
      setLoading(true);
      const response = await axios.get(
        `https://api.countrystatecity.in/v1/countries/${countryCode}/states`,
        {
          headers: {
            "X-CSCAPI-KEY":
              "VElGVWJxblkwQWlaTlVtUVRTZTlKSHRvSnJhZDBKenVBS2l0am5hdw==",
          },
        }
      );

      if (response.data && response.data.length) {
        setStates([
          { label: "Select State", value: "" },
          ...response.data.map((state) => ({
            label: state.name,
            value: state.name,
            code: state.iso2,
          })),
        ]);
      } else {
        setStates([{ label: "No States Found", value: "" }]);
      }
    } catch (error) {
      console.error("Error fetching states:", error);
      throw error; // Re-throw to handle it in the calling function
    } finally {
      setLoading(false);
    }
  };

  const fetchCities = async (countryCode, stateCode) => {
    if (!countryCode || !stateCode) return; // Prevent unnecessary calls if no country/state code
    try {
      setLoading(true);
      const response = await axios.get(
        `https://api.countrystatecity.in/v1/countries/${countryCode}/states/${stateCode}/cities`,
        {
          headers: {
            "X-CSCAPI-KEY":
              "VElGVWJxblkwQWlaTlVtUVRTZTlKSHRvSnJhZDBKenVBS2l0am5hdw==",
          },
        }
      );

      if (response.data && response.data.length) {
        setCities([
          { label: "Select City", value: "" },
          ...response.data.map((city) => ({
            label: city.name,
            value: city.name,
          })),
        ]);
      } else {
        setCities([{ label: "No Cities Found", value: "" }]);
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
      throw error; // Re-throw to handle it in the calling function
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({ ...prevState, [name]: value }));

    // Reset dependent fields
    if (name === "country") {
      setFormData((prevState) => ({ ...prevState, state: "", city: "" }));
    } else if (name === "state") {
      setFormData((prevState) => ({ ...prevState, city: "" }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formDataObj = { ...formData };
    setLoading(true);

    console.log("fvbfkv", formData);
    try {
      const response = await axios.put(`${backendUrl}/user/updateUser`, {
        id: user.id,
        user: formDataObj,
      });

      setSuccess("Profile Updated Successfully.");
      localStorage.setItem(
        "TalentSyncUser",
        JSON.stringify(response.data.updatedUser)
      );
      setLoading(false);
      setTimeout(() => {
        navigate("/dashboard/profile");
      }, 2000);
    } catch (err) {
      console.log(err);
      setErr("Error submitting form.");
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    seterror(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, left: open });
  };

  return (
    <Box style={{ backgroundColor: "#f7f8fa", display: "flex", flex: 1 }}>
      <MenuDrawer admin toggleDrawer={toggleDrawer} state={state} />
      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <SnackbarComponent
        err={err}
        success={success}
        setErr={setErr}
        setSuccess={setSuccess}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          flex: 1,
          overflow: "hidden",
        }}
      >
        <AppBar title="Talent Sync Agency" toggleDrawer={toggleDrawer} />

        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        ></Menu>

        <Grid container p={2}>
          <Grid item xs={12} md={12}>
            <Box
              mb={2}
              sx={{
                backgroundColor: "#fff",
                borderRadius: "12px",
              }}
            >
              {/* <TitleWithoutSearchBar title="Profile" halfWidth /> */}

              <section
                className=""
                style={{
                  position: "relative",
                  display: "block",
                  zIndex: "1",
                }}
              >
                <div
                  className="container"
                  style={{ paddingTop: "10px", paddingBottom: "10px" }}
                >
                  <div
                    className="contact-page__bottom"
                    style={{ marginTop: "0px !important" }}
                  >
                    <div className="comment-form">
                      <h3 className="comment-one__title">
                        {user?.name}'s Profile!
                      </h3>
                      <p>You can update your profile here.</p>
                      <Form
                        className="comment-one__form contact-form-validated"
                        onSubmit={handleSubmit}
                      >
                        <div className="row">
                          <div className="col-xl-6 col-lg-6">
                            <div className="comment-form__input-box">
                              <input
                                type="text"
                                name="name"
                                placeholder="Your Name"
                                id="name"
                                value={formData.name}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6">
                            <div className="comment-form__input-box">
                              <input
                                type="number"
                                placeholder="Your Age"
                                name="age"
                                id="age"
                                value={formData.age}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6">
                            <div className="comment-form__input-box">
                              <input
                                placeholder="Your Email"
                                type="email"
                                name="email"
                                id="email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6">
                            <div className="comment-form__input-box">
                              <input
                                type="text"
                                name="contact"
                                id="contact"
                                placeholder="Contact no with country code eg: '(+12) 123456789'"
                                value={formData.contact}
                                onChange={handleChange}
                                required
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6">
                            <div className="comment-form__input-box">
                              <input
                                type="text"
                                name="nationality"
                                id="nationality"
                                placeholder="Nationality"
                                value={formData.nationality}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6">
                            <div className="comment-form__input-box">
                              <input
                                type="text"
                                name="residency"
                                id="residency"
                                placeholder="Residency"
                                value={formData.residency}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        {/* <div className="row">
                          <div className="col-xl-6 col-lg-6">
                            <div className="comment-form__input-box">
                              <select
                                name="referralSource"
                                id="referralSource"
                                value={formData.referralSource}
                                onChange={handleChange}
                                
                              >
                                <option default value="">
                                  {" "}
                                  How did you hear about us?
                                </option>
                                <option value="LinkedIn">LinkedIn</option>
                                <option value="Instagram">Instagram</option>
                                <option value="Website">Website</option>
                                <option value="Google Ad">Google Ad</option>
                                <option value="Ad">Ad</option>
                                <option value="Directly from salesperson">
                                  Directly from salesperson
                                </option>
                              </select>
                            </div>
                          </div>
                        </div> */}
                        <div className="row">
                          <div className="col-xl-6 col-lg-6">
                            <div className="comment-form__input-box">
                              <select
                                name="country"
                                value={formData.country}
                                onChange={handleChange}
                              >
                                {countries.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6">
                            <div className="comment-form__input-box">
                              <select
                                name="state"
                                value={formData.state}
                                onChange={handleChange}
                              >
                                {states.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6">
                            <div className="comment-form__input-box">
                              <select
                                name="city"
                                value={formData.city}
                                onChange={handleChange}
                              >
                                {cities.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6">
                            <div className="comment-form__input-box">
                              <select
                                name="referralSource"
                                id="referralSource"
                                value={formData.referralSource}
                                onChange={handleChange}
                              >
                                <option default value="">
                                  {" "}
                                  How did you hear about us?
                                </option>
                                <option value="LinkedIn">LinkedIn</option>
                                <option value="Instagram">Instagram</option>
                                <option value="Reddit">Reddit</option>
                                <option value="Website">Website</option>
                                <option value="Google Ad">Google Ad</option>
                                <option value="Ad">Ad</option>
                                <option value="Directly from salesperson">
                                  Directly from salesperson
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6">
                            <div className="comment-form__input-box">
                              <input
                                type="text"
                                name="jobTitles"
                                id="jobTitles"
                                placeholder="Desired Jobs"
                                value={formData.jobTitles}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6">
                            <div className="comment-form__input-box">
                              <input
                                type="number"
                                name="experience"
                                id="experience"
                                placeholder="Years of Experience"
                                value={formData.experience}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6">
                            <div className="comment-form__input-box">
                              <select
                                name="education"
                                value={formData.education}
                                onChange={(e) =>
                                  handleSelectChange(
                                    "education",
                                    e.target.value
                                  )
                                }
                              >
                                {educationLevelOptions.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6">
                            <div className="comment-form__input-box">
                              <select
                                name="targetCompanySize"
                                value={formData.targetCompanySize} // Use the actual string value
                                onChange={(e) =>
                                  handleSelectChange(
                                    "targetCompanySize",
                                    e.target.value
                                  )
                                } // Use e.target.value
                              >
                                {companySizeOptions.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6">
                            <div className="comment-form__input-box">
                              <select
                                value={selectedIndustry}
                                onChange={handleIndustryChange}
                              >
                                <option value="">Select Industry</option>
                                {industryOptions.map((option) => (
                                  <option
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="col-xl-5 col-lg-5">
                            <div className="comment-form__input-box">
                              <input
                                type="text"
                                name="avoidCompanies"
                                id="avoidCompanies"
                                placeholder="Companies to avoid reaching out"
                                onChange={(e) => {
                                  setAvoidCompany(e.target.value);
                                }}
                                value={avoidCompany}
                              />
                            </div>
                          </div>
                          <div className="col-xl-1 col-lg-1">
                            <button
                              onClick={addAvoidCompany}
                              type="button"
                              style={{
                                height: "60px",
                                width: "60px",
                                border: "1px solid #1a73e9",
                                color: "#1a73e9",
                                background: "transparent",
                                borderRadius: "10px",
                              }}
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <div className="row">
                          {/* Selected Industries */}
                          <div className="col-xl-6 col-lg-6">
                              {formData.industries && formData.industries.length > 0 ? (
                                  <div>
                                      <h7>Selected Industries</h7>
                                      <div
                                          style={{
                                              border: "2px solid gray",
                                              borderRadius: "10px",
                                              padding: "10px",
                                              margin: "10px 0",
                                          }}
                                      >
                                          {formData.industries.map((industry, index) => (
                                              <div
                                                  key={index}
                                                  style={{
                                                      borderRadius: "10px",
                                                      border: "1px solid blue",
                                                      padding: "5px 10px",
                                                      margin: "5px",
                                                      display: "inline-block",
                                                  }}
                                              >
                                                  {industry}
                                                  <Badge
                                                      style={{
                                                          cursor: "pointer",
                                                          marginLeft: "10px",
                                                      }}
                                                      onClick={() => {
                                                          removeIndustry(industry);
                                                      }}
                                                  >
                                                      X
                                                  </Badge>
                                              </div>
                                          ))}
                                      </div>
                                  </div>
                              ) : (
                                  <p>No industries selected. Please select an industry.</p>
                              )}
                          </div>

                          <div className="col-xl-6 col-lg-6">
                              {formData.avoidCompanies && formData.avoidCompanies.length > 0 ? (
                                  <div>
                                      <h7>Avoiding Companies List</h7>
                                      <div
                                          style={{
                                              border: "2px solid gray",
                                              borderRadius: "10px",
                                              padding: "10px",
                                              margin: "10px 0",
                                          }}
                                      >
                                          {formData.avoidCompanies.map((company, index) => (
                                              <div
                                                  key={index}
                                                  style={{
                                                      borderRadius: "10px",
                                                      border: "1px solid blue",
                                                      padding: "5px 10px",
                                                      margin: "5px",
                                                      display: "inline-block",
                                                  }}
                                              >
                                                  {company}
                                                  <Badge
                                                      style={{
                                                          cursor: "pointer",
                                                          marginLeft: "10px",
                                                      }}
                                                      onClick={() => {
                                                          removeAvoidCompany(company);
                                                      }}
                                                  >
                                                      X
                                                  </Badge>
                                              </div>
                                          ))}
                                      </div>
                                  </div>
                              ) : (
                                  <p>No companies to avoid. Please add companies to avoid.</p>
                              )}
                          </div>
                        </div>
                        {/* Add space between rows */}
                        <div style={{ height: "30px" }}></div> 
                        <div className="row">
                          <div className="col-xl-6 col-lg-6">
                            <div className="comment-form__input-box">
                              <Label for="resume">Resume</Label>
                              <Input
                                type="file"
                                name="resume"
                                id="resume"
                                onChange={handleFileChange}
                              />
                              <a href={formData.resume} target="_blank">
                                Open Uploaded File
                              </a>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6">
                            <div className="comment-form__input-box">
                              <Label for="avatar">Avatar</Label>
                              <Input
                                type="file"
                                name="avatar"
                                id="avatar"
                                onChange={handleFileChange}
                              />
                              <a href={formData.avatar} target="_blank">
                                Open Uploaded File
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-6 col-lg-6">
                            <div className="comment-form__input-box">
                              <Label for="professionalCert">
                                Professional Certificate
                              </Label>
                              <Input
                                type="file"
                                name="professionalCert"
                                id="professionalCert"
                                onChange={handleFileChange}
                              />
                              <a
                                href={formData.professionalCert}
                                target="_blank"
                              >
                                Open Uploaded File
                              </a>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6">
                            <div className="comment-form__input-box">
                              <Label for="educationCert">
                                Education Certificate
                              </Label>
                              <Input
                                type="file"
                                name="educationCert"
                                id="educationCert"
                                onChange={handleFileChange}
                              />
                              <a href={formData.educationCert} target="_blank">
                                Open Uploaded File
                              </a>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-12 col-lg-12">
                            <div className="comment-form__input-box text-message-box">
                              <textarea
                                name="coverLetter"
                                placeholder="Please provide detailed background information about your previous experiences, skills, character, and goals. This information will help us achieve the best possible outcome."
                                id="coverLetter"
                                value={formData.coverLetter}
                                onChange={handleChange}
                              ></textarea>
                            </div>
                            <div className="comment-form__input-box text-message-box">
                              <textarea
                                placeholder="Space to include any additional information you would like to share."
                                name="additionalInfo"
                                id="additionalInfo"
                                value={formData.additionalInfo}
                                onChange={handleChange}
                              ></textarea>
                            </div>
                            <div className="comment-form__btn-box">
                              <button
                                type="submit"
                                className="thm-btn comment-form__btn"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                      <div className="result"></div>
                    </div>
                  </div>
                </div>
              </section>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Profile;
