import React, { useState } from "react";
import { GoogleLogin } from "@react-oauth/google";
import PricingOneShape2 from "../../assets/images/shapes/pricing-one-shape-2.png";
import PricingOneShape3 from "../../assets/images/shapes/pricing-one-shape-3.png";
import PricingOneShape4 from "../../assets/images/shapes/pricing-one-shape-4.png";
import PricingOneShape1 from "../../assets/images/shapes/pricing-one-shape-1.png";
import PricingOneIcon2 from "../../assets/images/icon/pricing-one-icon-2.png";
import SiteFooterFourShape1 from "../../assets/images/shapes/site-footer-four-shape-1.png";
import Logo2 from "../../assets/images/resources/logo-2.png";
import CTAThreeBgShape2 from "../../assets/images/shapes/cta-three-bg-shape-2.png";
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import SnackbarComponent from "../../components/Snackbar/Snackbar";
import "./pricing.css";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import TalentSyncLogo from "../../assets/images/talentSync_Logo.png";

const Pricing = () => {
  const [open, setOpen] = React.useState(false);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const DrawerList = (
    <Box
      sx={{ width: 250, backgroundColor: "#040b1e", color: "white" }}
      role="presentation"
      onClick={toggleDrawer(false)}
    >
      <div
        style={{
          display: "flex ",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          height: "fit-content",
        }}
      >
        <img src={TalentSyncLogo} style={{ width: "55%" }} alt="logo"></img>
        <span
          className="mobile-nav__close mobile-nav__toggler"
          style={{ marginTop: "5%" }}
        >
          <i className="fa fa-times" onClick={toggleDrawer(false)}></i>
        </span>
      </div>
      <List>
        {/* Navigation Items */}
        <ListItem button component="a" href="/">
          <ListItemText
            primary="Home"
            sx={{ textAlign: "left", color: "white" }}
          />
        </ListItem>
        <Divider />
        <ListItem button component="a" href="/about">
          <ListItemText
            primary="About"
            sx={{ textAlign: "left", color: "white" }}
          />
        </ListItem>
        <Divider />
        <ListItem button component="a" href="/pricing">
          <ListItemText
            primary="Pricing"
            sx={{ textAlign: "left", color: "white" }}
          />
        </ListItem>
        <Divider />
        <ListItem button component="a" href="/how-it-works">
          <ListItemText
            primary="How It Works"
            sx={{ textAlign: "left", color: "white" }}
          />
        </ListItem>
        <Divider />
        <ListItem button component="a" href="/contact">
          <ListItemText
            primary="Contact"
            sx={{ textAlign: "left", color: "white" }}
          />
        </ListItem>
        <Divider />
      </List>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "20px",
          marginTop: "8%",
        }}
      >
        <a href="#" className="fab fa-twitter" style={{ color: "white" }}></a>
        <a
          href="#"
          className="fab fa-facebook-square"
          style={{ color: "white" }}
        ></a>
        <a
          href="#"
          className="fab fa-pinterest-p"
          style={{ color: "white" }}
        ></a>
        <a href="#" className="fab fa-instagram" style={{ color: "white" }}></a>
      </div>
    </Box>
  );
  const [loginModalOpen, setLoginModalOpen] = useState(false);
  const navigate = useNavigate();
  const [err, setErr] = useState(null);
  const [success, setSuccess] = useState(null);
  const toggleLoginModal = () => setLoginModalOpen(!loginModalOpen);

  const handleLoginSuccess = (response) => {
    // Decode the JWT token to get user details
    const decoded = jwtDecode(response.credential);
    const userName = decoded.name;
    const userEmail = decoded.email;

    // Save user details in localStorage
    localStorage.setItem(
      "TalentSyncUser",
      JSON.stringify({ userName, userEmail })
    );

    // Show a success message
    setSuccess("Successfully logged in!");

    // Navigate to the profile form page
    navigate("/profile-form");
  };

  const handleLoginFailure = (error) => {
    console.log("Login Failed:", error);
    setErr("Failed to log in. Please try again.");
  };
  return (
    <div>
      <SnackbarComponent
        err={err}
        success={success}
        setErr={setErr}
        setSuccess={setSuccess}
      />
      <div className="page-wrapper">
        <header className="main-header-four">
          <div className="main-header-four__top">
            <div className="container">
              <div className="main-header-four__top-inner">
                <div className="main-header-four__top-left">
                  <div className="main-header-four__logo">
                    <a href="/">
                      <img src={TalentSyncLogo} style={{ width: "20%" }}></img>
                    </a>
                  </div>
                </div>
                <ul className="list-unstyled main-header-four__contact-list">
                  <li>
                    <div className="text">
                      <span>email</span>
                      <p>
                        <a href="/support@mail.talentsync.agency">
                          support@mail.talentsync.agency
                        </a>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <nav className="main-menu main-menu-four">
            <div className="main-menu-four__wrapper">
              <div className="container">
                <div className="main-menu-four__wrapper-inner">
                  <div className="main-menu-four__left">
                    <div className="main-menu-four__main-menu-box">
                      <a className="mobile-nav__toggler">
                        <i
                          className="fa fa-bars"
                          onClick={toggleDrawer(true)}
                        ></i>
                      </a>
                      <Drawer
                        sx={{
                          "& .MuiDrawer-paper": {
                            backgroundColor: "#040b1e",
                            px: "15px",
                            py: "30px",
                          },
                        }}
                        open={open}
                        onClose={toggleDrawer(false)}
                      >
                        {DrawerList}
                      </Drawer>
                      <ul className="main-menu__list">
                        <li className="dropdown">
                          <a href="/">Home </a>
                        </li>
                        <li>
                          <a href="/about">About</a>
                        </li>

                        <li>
                          <a href="/pricing">Pricing</a>
                        </li>

                        <li>
                          <a href="/how-it-works">How It Works</a>
                        </li>
                        <li>
                          <a href="/contact">Contact</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="main-menu-four__right">

                    {/* <div className="main-menu-four__btn-box">
                      <a
                        href="/contact"
                        className="main-menu-four__btn thm-btn"
                      >
                        GET A QUOTE
                      </a>
                    </div> */}
                    <div
                      className="main-menu-four__btn-box"
                      style={{ marginLeft: "10px" }}
                    >
                      <a href="/login" className="main-menu-four__btn thm-btn">
                        Log In
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </nav>
        </header>

        <div className="stricky-header stricked-menu main-menu main-menu-four">
          <div className="sticky-header__content"></div>
        </div>

        <section className="page-header" style={{ padding: "100px 0 100px" }}>
          <div className="page-header__bg"></div>
          <div className="container">
            <div className="page-header__inner">
              <h2>Pricing Plan</h2>
              <div className="thm-breadcrumb__box">
                <ul className="thm-breadcrumb list-unstyled">
                  <li>
                    <a href="/">Home</a>
                  </li>
                  <li>
                    <span>-</span>
                  </li>
                  <li>Pricing Plan</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section className="pricing-one">
          <div className="pricing-one__shape-2 float-bob">
            <img src={PricingOneShape2} alt="" />
          </div>
          <div className="pricing-one__shape-3 float-bob-y">
            <img src={PricingOneShape3} alt="" />
          </div>
          <div className="pricing-one__shape-4">
            <img src={PricingOneShape4} alt="" />
          </div>
          <div className="container">
            <div className="section-title text-center">
              <div className="section-title__tagline-box">
                <p className="section-title__tagline">CAREER PACKAGES</p>
              </div>
              <h3 className="section-title__title">
                Interested? Contact us today—it's free right now!
              </h3>
              <br />
            </div>
            <div className="row">
              <div
                className="col-xl-3 col-lg-3 wow fadeInLeft"
                data-wow-delay="100ms"
              ></div>
              <div
                className="col-xl-6 col-lg-6 wow fadeInUp"
                data-wow-delay="300ms"
              >
                <div className="pricing-one__single">
                  <div
                    className="pricing-one__single-inner"
                    style={{ justifyItems: "center" }}
                  >
                    <div className="pricing-one__shape-1">
                      <img src={PricingOneShape1} alt="" />
                    </div>
                    <div className="pricing-one__icon">
                      <img src={PricingOneIcon2} alt="" />
                    </div>
                    <p className="pricing-one__price">Coming Soon</p>
                    <ul className="pricing-one__points list-unstyled">
                      <li>
                        <div className="icon">
                          <span className="icon-check"></span>
                        </div>
                        <p>1000 HR</p>
                      </li>
                      <li>
                        <div className="icon">
                          <span className="icon-check"></span>
                        </div>
                        <p>Cover letter</p>
                      </li>
                      <li>
                        <div className="icon">
                          <span className="icon-check"></span>
                        </div>
                        <p>Resume</p>
                      </li>
                    </ul>
                    <br />
                    {/*
                    <div className="pricing-one__btn-box">
                      <a className="pricing-one__btn thm-btn" href="/login">
                        SELECT POLICY
                      </a>
                    </div>
                    */}
                  </div>
                </div>
              </div>

              <div
                className="col-xl-3 col-lg-3 wow fadeInRight"
                data-wow-delay="600ms"
              ></div>
            </div>
          </div>
        </section>
        <section className="cta-one cta-three">
          <div className="container">
            <div className="cta-one__inner">
              <div className="cta-one__bg" style={{ backgroundImage: `url('/static/media/cta-three-bg-shape.e9c0161ef88e1fcbc1f1.png')` }}></div>
              <div className="cta-one__title-box">
                <h3>Are you Intrested in <span>Free</span> Service.</h3>
                <p>We are here to help you. Currently we are in a testing phase if you are intrested to work together drop us a mail <br /> Lets Grow Together.</p>
              </div>
              <div className="cta-one__btn-box">
                <a href="/contact" className="cta-one__btn thm-btn">Drop US A Mail</a>
              </div>
            </div>
          </div>
        </section>
        
        
        <footer className="site-footer site-footer-three site-footer-four">
          <div className="site-footer__shape-1 float-bob-y">
            <img src={SiteFooterFourShape1} alt="" />
          </div>
          <div className="site-footer__top">
            <div className="container">
              <div className="site-footer__top-inner">
                <div className="row">
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 wow fadeInUp"
                    data-wow-delay="100ms"
                  >
                    <div className="footer-widget__column footer-widget__about">
                      <div className="footer-widget__logo">
                        {/* <!-- <a href="/"><img src="assets/images/resources/footer-logo-4.png"
                                                alt=""></a> --> */}
                        <a href="/">
                          <h2 style={{ color: "white" }}>Talentsync</h2>
                        </a>
                      </div>
                      <p className="footer-widget__about-text">
                        Get Noticed by Employers: TalentSync Finds You the
                        Perfect Job
                      </p>
                      <div className="footer-widget__emergency-call">
                        <a href="mailto:support@mail.talentsync.com">
                         support@mail.talentsync.com
                        </a>
                      </div>
                      {/*
                      <div className="footer-widget__social">
                        <a href="#">
                          <span className="icon-facebook"></span>
                        </a>
                        <a href="#">
                          <span className="icon-instagram-1"></span>
                        </a>
                        <a href="#">
                          <span className="icon-tik-tok"></span>
                        </a>
                        <a href="#">
                          <span className="icon-youtube"></span>
                        </a>
                      </div>
                      */}
                    </div>
                  </div>
                  <div
                    className="col-xl-6 col-lg-6 col-md-6 wow fadeInUp"
                    data-wow-delay="200ms"
                  >
                    <div className="footer-widget__column footer-widget__navigation">
                      <div className="footer-widget__title-box">
                        <h3 className="footer-widget__title">Navigation</h3>
                      </div>
                      <ul className="footer-widget__navigation-list list-unstyled">
                        <li>
                          <a href="/">Home</a>
                        </li>
                        <li>
                          <a href="/about">About Us</a>
                        </li>
                        <li>
                          <a href="/how-it-works">How It Works</a>
                        </li>
                        <li>
                          <a href="/pricing">Pricing</a>
                        </li>
                        <li>
                          <a href="/contact">Contact Us</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="site-footer__bottom">
            <div className="container">
              <div className="site-footer__bottom-inner">
                <p className="site-footer__bottom-text">
                  <a href="/PrivacyPolicy">Privacy Policy</a> &nbsp;|&nbsp; Copyright © 2024 Talentsync.Agency. All Rights Reserved
                </p>
              </div>
            </div>
          </div>
        </footer>


      </div>

      <div className="mobile-nav__wrapper">
        <div className="mobile-nav__overlay mobile-nav__toggler"></div>

        <div className="mobile-nav__content">
          <span className="mobile-nav__close mobile-nav__toggler">
            <i className="fa fa-times"></i>
          </span>

          <div className="logo-box">
            <a href="/" aria-label="logo image">
              <img src={Logo2} width="150" alt="" />
            </a>
          </div>

          <div className="mobile-nav__container"></div>

          <ul className="mobile-nav__contact list-unstyled">
            <li>
              <i className="fa fa-envelope"></i>
              <a href="/mailto:needhelp@packageName__.com">
                needhelp@sonchoy.com
              </a>
            </li>
            <li>
              <i className="fa fa-phone-alt"></i>
              <a href="/tel:666-888-0000">666 888 0000</a>
            </li>
          </ul>
          <div className="mobile-nav__top">
            <div className="mobile-nav__social">
              <a href="/#" className="fab fa-twitter"></a>
              <a href="/#" className="fab fa-facebook-square"></a>
              <a href="/#" className="fab fa-pinterest-p"></a>
              <a href="/#" className="fab fa-instagram"></a>
            </div>
          </div>
        </div>
      </div>

      <div className="search-popup">
        <div className="search-popup__overlay search-toggler"></div>
        <div className="search-popup__content">
          <form action="#">
            <label for="search" className="sr-only">
              search here
            </label>
            <input type="text" id="search" placeholder="Search Here..." />
            <button
              type="submit"
              aria-label="search submit"
              className="thm-btn"
            >
              <i className="fas fa-search"></i>
            </button>
          </form>
        </div>
      </div>

      <a
        href="/#"
        data-target="html"
        className="scroll-to-target scroll-to-top"
      >
        <i className="fas fa-arrow-up"></i>
      </a>
      {/* <Modal isOpen={loginModalOpen} toggle={toggleLoginModal} size="md">
        <ModalHeader toggle={toggleLoginModal}>Login</ModalHeader>
        <ModalBody>
          <Row>
            <Col xs="12">
              <Card className="shadow-sm p-3 bg-white rounded">
                <CardBody className="text-center">
                  <h2 className="mb-4">Sign in with Google</h2>
                  <p>Access your profile securely by signing in with Google</p>
                  <GoogleLogin
                    onSuccess={handleLoginSuccess}
                    onError={handleLoginFailure}
                    render={(renderProps) => (
                      <Button
                        color="primary"
                        onClick={renderProps.onClick}
                        disabled={renderProps.disabled}
                      >
                        <i className="fa fa-google mr-2"></i> Sign in with
                        Google
                      </Button>
                    )}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            style={{ borderRadius: "0" }}
            onClick={toggleLoginModal}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal> */}
    </div>
  );
};

export default Pricing;
